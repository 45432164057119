<template>
  <div id="login" />
</template>

<script>
export default {
  name: 'LogIn',
  created () {
    this.$store.dispatch('auth/login')
  }
}
</script>
